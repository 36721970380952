import React from 'react';
import { SupportedMimeType } from '@utils/mimeTypeExtensions';
import {
  Circle,
  Icon,
  Input,
  Spinner,
  Text,
  VStack,
  FormLabel,
} from '@chakra-ui/react';
import { FolderIcon, FolderOpenIcon } from '@heroicons/react/solid';

enum UIState {
  Idle = 'idle',
  Hover = 'hover',
  Invalid = 'invalid',
  Uploading = 'uploading',
}

export type FileDropAreaProps = {
  acceptedTypes: SupportedMimeType[];
  sizeLimitBytes: number;
  handleSuccess: (file: File) => void;
  handleError?: () => void;
};

export function FileDropArea({
  acceptedTypes,
  sizeLimitBytes,
  handleSuccess,
  handleError,
}: FileDropAreaProps): JSX.Element {
  const [uiState, setUiState] = React.useState<UIState>(UIState.Idle);

  const fileRef = React.useRef<HTMLInputElement>(null);

  const promptForFile = () => {
    fileRef.current?.click();
  };

  const fileIsValid = (f: File): boolean => {
    return fileTypeIsValid(f.type) && fileSizeIsValid(f.size);
  };

  const fileTypeIsValid = (mime: string): mime is SupportedMimeType => {
    if (acceptedTypes.length === 0) {
      // Accept every file type if an empty array was provided.
      return true;
    }
    return acceptedTypes.includes(mime as SupportedMimeType);
  };

  const fileSizeIsValid = (bytes: number): boolean => {
    if (!sizeLimitBytes) {
      return true;
    }
    return bytes <= sizeLimitBytes;
  };

  const handleDragEnter = (ev: React.DragEvent) => {
    const files = ev.dataTransfer.items;
    if (files.length > 0 && !fileTypeIsValid(files[0].type)) {
      setUiState(UIState.Invalid);
    } else {
      setUiState(UIState.Hover);
    }
  };

  const handleDragLeave = (ev: React.DragEvent) => {
    const dropArea = ev.currentTarget,
      elLeaving = ev.relatedTarget as Element;
    if (dropArea.contains(elLeaving)) {
      return;
    }
    setUiState(UIState.Idle);
  };

  const handleOver = (ev: React.DragEvent) => {
    ev.preventDefault();
  };

  const handleDrop = (ev: React.DragEvent) => {
    ev.preventDefault();
    handleDragLeave(ev);
    const files = ev.dataTransfer.files;
    validateAndUpload(files[0]);
  };

  const areaHandlers = {
    onDragEnter: handleDragEnter,
    onDragLeave: handleDragLeave,
    onDragOver: handleOver,
    onDrop: handleDrop,
  };

  const areaStyles = {
    rounded: '2xl',
    h: '316px',
    pt: '96px',
    spacing: '0',
  };

  const handleInput = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const files = ev.currentTarget.files ?? [];
    validateAndUpload(files[0]);
  };

  const validateAndUpload = (file: File) => {
    if (!file) {
      return;
    }
    if (!fileIsValid(file)) {
      handleError && handleError();
      return;
    }
    void uploadFile(file);
  };

  const uploadFile = async (file: File) => {
    setUiState(UIState.Uploading);
    handleSuccess(file);
  };

  const buttonEl = () => {
    const v = uiState == UIState.Idle ? 'visible' : 'hidden';
    return (
      <div style={{ visibility: v }}>
        <Text
          as='button'
          textStyle='sm-medium'
          mt='44px'
          color='primary.500'
          onClick={promptForFile}
        >
          Or select file
        </Text>
        <FormLabel hidden htmlFor='imageUploader'>
          image uploader
        </FormLabel>
        <Input
          id='imageUploader'
          type='file'
          accept={acceptedTypes.join(',')}
          display='none'
          ref={fileRef}
          onChange={handleInput}
        />
      </div>
    );
  };

  const states = {
    idle: (
      <VStack bg='gray.50' {...areaStyles} {...areaHandlers}>
        <Circle size='64px' bg='gray.100' mb='4'>
          <Icon as={FolderIcon} boxSize='24px' color='gray.500' />
        </Circle>

        <Text textStyle='md-normal' color='gray.300'>
          Drag file here
        </Text>

        {buttonEl()}
      </VStack>
    ),
    hover: (
      <VStack
        bg='#F7F8FE'
        boxShadow='dropAreaHover'
        {...areaStyles}
        {...areaHandlers}
      >
        <Circle size='64px' bg='gray.100' mb='4'>
          <Icon as={FolderOpenIcon} boxSize='24px' color='gray.500' />
        </Circle>

        <Text textStyle='md-normal' color='gray.300'>
          Drop file to upload
        </Text>

        {buttonEl()}
      </VStack>
    ),
    invalid: (
      <VStack
        bg='#f7f2f2'
        boxShadow='dropAreaHoverUnsupported'
        {...areaStyles}
        {...areaHandlers}
      >
        <Circle size='64px' bg='gray.100' mb='4'>
          <Icon as={FolderIcon} boxSize='24px' color='gray.500' />
        </Circle>

        <Text textStyle='md-normal' color='gray.300'>
          Unsupported file type
        </Text>
        {buttonEl()}
      </VStack>
    ),
    uploading: (
      <VStack
        bg='#F7F8FE'
        boxShadow='dropAreaHover'
        {...areaStyles}
        {...areaHandlers}
      >
        <Spinner mb='8' />
        <Text textStyle='md-normal' color='gray.300'>
          Uploading file
        </Text>
      </VStack>
    ),
  };

  return states[uiState];
}
