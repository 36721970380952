import React from 'react';

import { Box } from '@chakra-ui/react';

import { MenuGroupOption, MenuGroupOptions } from '../../Menu';

import { FilterIcon } from '@components/common/Icons';

export type DropdownFilters = {
  [index: string]: { value: any; label: string };
};

export type DropdownFilterProps = {
  filters: MenuGroupOption;
};

const DropdownFilter = ({ filters }: DropdownFilterProps): JSX.Element => {
  const hasCheckedValue = Object.keys(filters).some(filterKey => {
    return Boolean(filters[filterKey].defaultValue);
  });
  const iconColor = hasCheckedValue ? 'primary.500' : 'gray.400';

  return (
    <Box position='relative'>
      <MenuGroupOptions
        type='checkbox'
        options={filters}
        triggerButtonProps={{
          children: 'Filter',
          'aria-label': 'Open filters dropdown',
          iconProps: { icon: FilterIcon, color: iconColor },
        }}
      />
    </Box>
  );
};

export default DropdownFilter;
