import React from 'react';
import { HStack, Img, Text } from '@chakra-ui/react';
import { User } from '@types';
import { useSaml } from '@hooks';

type EnterpriseAccountListProps = {
  user: User;
};

export function EnterpriseAccountList({
  user,
}: EnterpriseAccountListProps): JSX.Element {
  const { getSamlProviderLogoUrl, getSamlProviderName } = useSaml();

  // Duplication since we don't have access to the User from @clerk/types
  const samlAccounts = user.saml_accounts;

  if (samlAccounts.length === 0) {
    return (
      <Text textStyle='md-normal' color='gray.500'>
        (none)
      </Text>
    );
  }

  return (
    <>
      {samlAccounts.map(samlAccount => (
        <HStack key={samlAccount.id} mb='2'>
          <Img src={getSamlProviderLogoUrl(samlAccount.provider)} />

          <Text textStyle='md-normal'>
            {getSamlProviderName(samlAccount.provider)} (
            {samlAccount.email_address})
          </Text>
        </HStack>
      ))}
    </>
  );
}
