import React from 'react';

import { Tooltip } from '../tooltip';
import { HelpCircleIcon } from '../../assets/icons';

// @ts-ignore
import styles from './HintTooltip.module.scss';

export function HintTooltip({
  content,
}: {
  content: string | React.ReactNode;
}): JSX.Element {
  return (
    <Tooltip content={content} direction='left' tipClassName={styles.tip}>
      <HelpCircleIcon />
    </Tooltip>
  );
}
