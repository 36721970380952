import React from 'react';
import {
  Box,
  CloseButton,
  Flex,
  HStack,
  Icon,
  Text,
  BoxProps,
  FlexProps,
  TextProps,
} from '@chakra-ui/react';
import { CardContainer, Hint, Subtitle } from './CardElements';
import { ExclamationCircleIcon } from '@heroicons/react/solid';

type CardSinglePanelProps = {
  title: string | JSX.Element;
  titleContainerStyles?: FlexProps;
  subtitleStyles?: TextProps;
  subtitle?: string | JSX.Element;
  hint?: string;
  isHintFaded?: boolean;
  disabled?: boolean;
  nonClickable?: boolean;
  onDismiss?: () => void;
  icon?: JSX.Element;
  children?: React.ReactNode;
} & Omit<BoxProps, 'title'>;

export function CardInfoPanel({
  title,
  titleContainerStyles,
  subtitleStyles,
  subtitle,
  hint,
  icon,
  isHintFaded = true,
  children,
  onDismiss,
  ...rest
}: CardSinglePanelProps): JSX.Element {
  return (
    <CardContainer p={0} {...rest} pos='relative'>
      <HStack spacing={4} alignItems='flex-start'>
        <Flex
          bgColor='gray.50'
          alignSelf='stretch'
          width={14}
          flexDirection='column'
          alignItems='center'
          pt={6}
          borderTopLeftRadius='xl'
          borderBottomLeftRadius='xl'
        >
          {icon ?? (
            <Icon as={ExclamationCircleIcon} boxSize={6} color='primary.500' />
          )}
        </Flex>

        <Box flex='1' pt={6} pb={8}>
          <Flex
            align='center'
            justify='space-between'
            mb={subtitle ? 1 : 6}
            {...titleContainerStyles}
          >
            {typeof title === 'string' ? (
              <Text textStyle='text-lg'>{title}</Text>
            ) : (
              title
            )}
          </Flex>

          {subtitle && <Subtitle {...subtitleStyles}>{subtitle}</Subtitle>}

          {hint && <Hint text={hint} isFaded={isHintFaded} />}

          {children}
        </Box>

        {onDismiss ? (
          <CloseButton
            title='Dismiss'
            alignSelf='flex-start'
            position='relative'
            right={3}
            top={4}
            color='gray.300'
            onClick={onDismiss}
          />
        ) : (
          // Reserve white space
          <Box position='relative' width={'2rem'} />
        )}
      </HStack>
    </CardContainer>
  );
}
