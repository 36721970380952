import React from 'react';
import {
  forwardRef,
  useMenuButton,
  ComponentWithAs,
  IconProps,
  Icon,
} from '@chakra-ui/react';

import { SecondaryButton, SecondaryButtonProps } from './SecondaryButton';

/**
 * Trigger button is being used in components that opens a dropdown component.
 * e.g DropdownFilter,Μenu etc.
 *
 * We wrap inside a forward ref in order to work with Chakra menus.
 *
 * From the Chakra docs:
 * If you decide to pass your own component to MenuButton,
 * it needs to accept a ref so that the MenuList can be positioned correctly.
 * You can use Chakra's forwardRef to supply the ref along with being able to use Chakra props.
 * Without a ref, the MenuList will render in an undefined position.
 */

type MenuButtonProps = Pick<
  ReturnType<typeof useMenuButton>,
  | 'id'
  | 'data-active'
  | 'aria-expanded'
  | 'aria-haspopup'
  | 'aria-controls'
  | 'onClick'
  | 'onKeyDown'
>;
export type TriggerButtonProps = {
  iconProps?: {
    icon:
      | ((props: React.ComponentProps<'svg'>) => JSX.Element)
      | ComponentWithAs<'svg', IconProps>;
  } & IconProps;
} & Omit<SecondaryButtonProps, 'as'> &
  Omit<Partial<MenuButtonProps>, 'as'>;
export const TriggerButton = forwardRef<TriggerButtonProps, 'button'>(
  ({ iconProps, ...props }, ref) => {
    const { icon } = iconProps || {};
    return (
      <SecondaryButton
        ref={ref}
        aria-label='open menu'
        leftIcon={icon ? <Icon as={icon} {...iconProps} /> : null}
        {...props}
      />
    );
  },
);
