import React from 'react';

import {
  Menu,
  MenuButton,
  MenuList,
  MenuItemOption,
  MenuItemOptionProps as ChakraMenuItemOptionProps,
  MenuOptionGroup,
  MenuOptionGroupProps,
  MenuDivider,
  Box,
  ComponentWithAs,
} from '@chakra-ui/react';

import { TriggerButtonProps, TriggerButton } from '../Buttons';

export type MenuItemOptionProps = {
  label: string;
  key: string;
} & ChakraMenuItemOptionProps;

export type MenuGroupOption = Record<
  string,
  {
    title: string;
    defaultValue: string | string[];
    items: Record<string, MenuItemOptionProps>;
  } & MenuOptionGroupProps
>;

export type MenuGroupOptionsProps = {
  options: MenuGroupOption;
  MenuTriggerButton?: ComponentWithAs<'button', TriggerButtonProps>;
  type?: 'checkbox' | 'radio';
  triggerButtonProps?: TriggerButtonProps;
};

const renderGroupItemOptions = (items: Record<string, MenuItemOptionProps>) => {
  const itemKeys = Object.keys(items);
  return itemKeys.map(currentItemKey => {
    const { key, value, label, ...rest } = items[currentItemKey];
    return (
      <MenuItemOption
        key={key}
        value={value}
        _checked={{
          '.chakra-menu__icon': {
            color: 'primary.500',
          },
        }}
        fontSize='sm'
        fontWeight='medium'
        color='gray.600'
        {...rest}
      >
        {label}
      </MenuItemOption>
    );
  });
};

export const MenuGroupOptions = ({
  options,
  MenuTriggerButton = TriggerButton,
  type = 'radio',
  triggerButtonProps,
}: MenuGroupOptionsProps): JSX.Element => {
  const groupKeys = Object.keys(options);

  return (
    <Menu placement='bottom-end' closeOnSelect={false}>
      <MenuButton as={MenuTriggerButton} {...triggerButtonProps} />
      <MenuList zIndex='sticky'>
        {groupKeys.map((groupKey, index) => {
          const currentGroupOption = options[groupKey];
          const { defaultValue, title, items, ...rest } = currentGroupOption;
          return (
            <Box key={groupKey}>
              <MenuOptionGroup
                color='gray.700'
                fontSize='sm'
                defaultValue={defaultValue}
                title={title}
                type={type}
                textTransform='none'
                {...rest}
              >
                {renderGroupItemOptions(items)}
                {index < groupKeys.length - 1 && (
                  <MenuDivider aria-label={`divider after ${title}`} />
                )}
              </MenuOptionGroup>
            </Box>
          );
        })}
      </MenuList>
    </Menu>
  );
};
