import React from 'react';
import { forwardRef, ButtonProps, Button } from '@chakra-ui/react';

export type LinkButtonProps = {} & ButtonProps;
export const LinkButton = forwardRef<ButtonProps, 'button'>((props, ref) => (
  <Button
    variant='link'
    _hover={{
      svg: {
        color: 'primary.500',
      },
    }}
    ref={ref}
    {...props}
  >
    {props.children}
  </Button>
));
