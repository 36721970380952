import React from 'react';
import {
  Tooltip,
  Icon,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  Link as ChakraLink,
  useClipboard,
  BoxProps,
} from '@chakra-ui/react';
import { InputBox } from '@components/common';
import {
  ClipboardCheckIcon,
  ClipboardIcon,
  ExternalLinkIcon,
} from '@heroicons/react/solid';
import { useAnalytics } from '@hooks';
import InputPreviewSkeleton from './Skeleton';

type InputPreviewProps = {
  label?: string | JSX.Element;
  value: string;
  description?: string;
  info?: string;
  isLoading?: boolean;
  disableCopy?: boolean;
  disableExternalLink?: boolean;
} & BoxProps;

export function InputPreview({
  label,
  value,
  description,
  info,
  isLoading,
  disableCopy,
  disableExternalLink,
  ...rest
}: InputPreviewProps): JSX.Element {
  const { track } = useAnalytics();
  const { onCopy, hasCopied } = useClipboard(value);

  const copy = () => {
    onCopy();

    track(
      'Dashboard_Hosted Pages Screen_Quick Copy Copy to Clipboard Button Clicked',
      {
        surface: 'Dashboard',
        location: 'Hosted Pages Screen',
      },
    );
  };

  return (
    <InputBox
      info={info}
      p={0}
      label={label}
      description={description}
      width='100%'
    >
      {isLoading ? (
        <InputPreviewSkeleton />
      ) : (
        <InputGroup>
          <Input
            overflow='hidden'
            textOverflow='ellipsis'
            bg='blackAlpha.50'
            readOnly
            defaultValue={value}
            pr='4rem'
            color='blackAlpha.700'
            borderColor='blackAlpha.300'
            {...rest}
          />
          {(!disableCopy || !disableExternalLink) && (
            <InputRightElement width='auto'>
              <Tooltip hasArrow placement='top' label='Copy' bg='gray.900'>
                <IconButton
                  size='sm'
                  aria-label={hasCopied ? 'Copied' : 'Copy'}
                  bg='none'
                  _focus={{ bg: 'none' }}
                  _hover={{ bg: 'none' }}
                  _active={{ bg: 'none' }}
                  onClick={copy}
                >
                  <Icon
                    as={hasCopied ? ClipboardCheckIcon : ClipboardIcon}
                    color='gray.300'
                    boxSize={5}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip hasArrow placement='top' label='Visit' bg='gray.900'>
                <IconButton
                  as={ChakraLink}
                  href={value}
                  target='_blank'
                  size='sm'
                  aria-label={`Visit ${label}`}
                  bg='none'
                  _focus={{ bg: 'none' }}
                  _hover={{ bg: 'none' }}
                  _active={{ bg: 'none' }}
                >
                  <Icon as={ExternalLinkIcon} color='gray.300' boxSize={5} />
                </IconButton>
              </Tooltip>
            </InputRightElement>
          )}
        </InputGroup>
      )}
    </InputBox>
  );
}
