import React from 'react';
import {
  Menu,
  MenuProps,
  MenuButton,
  MenuList,
  MenuItem as ChakraMenuItem,
  MenuItemProps as ChakraMenuItemProps,
  ComponentWithAs,
} from '@chakra-ui/react';
import { TriggerButtonProps, TriggerButton } from '../Buttons';

export type MenuItemProps = ChakraMenuItemProps;
export const MenuItem = ChakraMenuItem;

export type MenuActionProps = {
  items: Record<string, MenuItemProps>;
  MenuTriggerButton?: ComponentWithAs<'button', TriggerButtonProps>;
  triggerButtonProps?: TriggerButtonProps;
  menuProps?: MenuProps;
};

export const MenuAction = ({
  items,
  menuProps,
  MenuTriggerButton = TriggerButton,
  triggerButtonProps,
}: MenuActionProps): JSX.Element => {
  const itemsKeys = Object.keys(items);
  return (
    <Menu {...menuProps}>
      <MenuButton as={MenuTriggerButton} {...triggerButtonProps} />
      <MenuList>
        {itemsKeys.map(itemKey => {
          const currentItem = items[itemKey];
          return <ChakraMenuItem key={itemKey} {...currentItem} />;
        })}
      </MenuList>
    </Menu>
  );
};

export type MenuActionWithCustomChildrenProps = {
  children: React.ReactNode;
  menuProps?: MenuProps;
  MenuTriggerButton?: ComponentWithAs<'button', TriggerButtonProps>;
  triggerButtonProps?: TriggerButtonProps;
};
export const MenuActionWithCustomChildren = ({
  children,
  menuProps,
  MenuTriggerButton,
  triggerButtonProps,
}: MenuActionWithCustomChildrenProps): JSX.Element => {
  return (
    <Menu {...menuProps}>
      <MenuButton as={MenuTriggerButton} {...triggerButtonProps} />
      <MenuList>{children}</MenuList>
    </Menu>
  );
};
