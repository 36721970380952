import React from 'react';
import { Image, ImageProps } from '@chakra-ui/image';
import { User } from '../../types';

export type UserAvatarProps = ImageProps & {
  user: Pick<
    User,
    'first_name' | 'last_name' | 'profile_image_url' | 'image_url'
  >;
};

export function UserAvatar({
  user,
  ...imageProps
}: UserAvatarProps): JSX.Element {
  imageProps.borderRadius ||= '50%';

  return <Image {...imageProps} src={user.image_url} />;
}
