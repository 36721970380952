import React from 'react';
import {
  Code,
  Icon,
  Stack,
  Text,
  Tooltip,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { WarningIcon } from '@clerk-ui/assets/icons';

type VariableTagProps = {
  label: string;
  warning?: string;
  onClick: () => void;
  disabled: boolean;
};

const VariableTag = ({
  label,
  warning,
  onClick,
  disabled,
}: VariableTagProps) => (
  <WrapItem mb='0px !important'>
    <Code
      display={'flex'}
      gap={2}
      alignItems='center'
      onClick={onClick}
      colorScheme='gray'
      cursor={disabled ? 'not-allowed' : 'pointer'}
      px='6px !important'
    >
      {label}
      {warning && (
        <Tooltip hasArrow label={warning} placement='top'>
          <span>
            <Icon as={WarningIcon} boxSize={4} color='blackAlpha.700' />
          </span>
        </Tooltip>
      )}
    </Code>
  </WrapItem>
);

type VariableWidgetProps = {
  variableName?: string;
  variables: Array<{ label: string; warning?: string }>;
  delimiters: string[];
  handleVariableClick: (text: string) => void;
  disabled?: boolean;
  helpSection?: React.ReactNode;
};

const formatVariable = (text: string, delimiters: string[]) =>
  `${delimiters[0]}${text}${delimiters[1]}`;

export function VariableWidget({
  variableName = 'variable',
  variables,
  delimiters,
  handleVariableClick,
  disabled = false,
  helpSection,
  ...rest
}: VariableWidgetProps): JSX.Element {
  return (
    <Stack pt='5' spacing='3' {...rest}>
      <Text textStyle='sm-medium' color='gray.500'>
        Insert {`${variableName}s`}
      </Text>

      <Wrap pb='8' spacing='4' overflow='visible'>
        {variables.map((variable, index) => (
          <VariableTag
            key={index}
            label={variable.label}
            warning={variable.warning}
            onClick={() =>
              !disabled &&
              handleVariableClick(formatVariable(variable.label, delimiters))
            }
            disabled={disabled}
          />
        ))}
      </Wrap>

      {helpSection}
    </Stack>
  );
}
