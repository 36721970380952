import React from 'react';
import { CardContainer } from '@components/common';
import { Text, Flex, Button } from '@chakra-ui/react';
import { DocumentSearch } from '@clerk-ui/assets/icons';

export function CardWithGenericError(): JSX.Element {
  return (
    <CardContainer spacing={6}>
      <Flex alignItems='center' flexDirection='column'>
        <Flex
          w='50px'
          height='50px'
          background='blackAlpha.50'
          borderRadius={50}
          alignItems='center'
          justifyContent='center'
        >
          <DocumentSearch />
        </Flex>
        <Text
          textStyle='lg-medium'
          display='inline-block'
          color='gray.900'
          mt={4}
        >
          Something went wrong
        </Text>

        <Text textStyle='sm-normal' color='gray.500' mt={2}>
          The resource could not be loaded. Try refreshing the page.
        </Text>

        <Button onClick={() => window.location.reload()} fontSize='12px' mt={8}>
          Refresh page
        </Button>
      </Flex>
    </CardContainer>
  );
}
