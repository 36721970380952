import React from 'react';

import {
  Skeleton as ChakraSkeleton,
  SkeletonProps as ChakraSkeletonProps,
} from '@chakra-ui/react';

export type SkeletonProps = ChakraSkeletonProps;
export const Skeleton = ({ ...props }: SkeletonProps): JSX.Element => {
  return (
    <ChakraSkeleton
      startColor='gray.50'
      endColor='gray.200'
      borderRadius='lg'
      fadeDuration={0.8}
      {...props}
    />
  );
};
