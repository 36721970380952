import React from 'react';

import { createIcon } from '@chakra-ui/react';

export const FilterIcon = createIcon({
  displayName: 'sortingIcon',
  viewBox: '0 0 20 20',
  defaultProps: { fill: 'currentcolor' },
  path: [
    <path
      key={1}
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3 5C3 4.44772 3.44772 4 4 4H17C17.5523 4 18 4.44772 18 5C18 5.55228 17.5523 6 17 6H4C3.44772 6 3 5.55228 3 5Z'
    />,
    <path
      key={2}
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5 10C5 9.44772 5.44772 9 6 9H15C15.5523 9 16 9.44772 16 10C16 10.5523 15.5523 11 15 11H6C5.44772 11 5 10.5523 5 10Z'
    />,
    <path
      key={3}
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7 15C7 14.4477 7.44772 14 8 14H13C13.5523 14 14 14.4477 14 15C14 15.5523 13.5523 16 13 16H8C7.44772 16 7 15.5523 7 15Z'
    />,
  ],
});

<svg
  width='20'
  height='20'
  viewBox='0 0 20 20'
  fill='none'
  xmlns='http://www.w3.org/2000/svg'
></svg>;
