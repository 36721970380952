import React from 'react';
import { Center, Spinner } from '@chakra-ui/react';

const FULL_SCREEN_STYLES: React.CSSProperties = {
  position: 'fixed',
  top: '0',
  left: '0',
};

type OverlayLoaderProps = {
  fullScreen?: boolean;
};

export function OverlayLoader({ fullScreen }: OverlayLoaderProps): JSX.Element {
  return (
    <Center
      bgColor='white'
      w='100%'
      h='100%'
      zIndex='overlay'
      style={fullScreen ? FULL_SCREEN_STYLES : null}
    >
      <Spinner />
    </Center>
  );
}
