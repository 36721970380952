import React from 'react';
import NextLink from 'next/link';
import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  ClerkLogoIcon,
  LogoPlateIcon,
  PlanUpgradeBackground,
} from '@clerk-ui/assets/icons';
import { Heading } from '@components/common';
import { useLocation } from '@hooks';
import { billingPath } from '@utils';

export function UpgradePlanModal({ isOpen, onClose }): JSX.Element {
  const initialRef = React.useRef();
  const { applicationId, instanceId } = useLocation();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={initialRef}
      size='xl'
    >
      <ModalOverlay />
      <ModalContent px='0.5em' py='3em' pos='relative' overflow='hidden'>
        <Icon
          as={PlanUpgradeBackground}
          position='absolute'
          top='0'
          right='0'
          w='398px'
          h='378px'
        />
        <Icon
          as={LogoPlateIcon}
          position='absolute'
          right='0%'
          top='37%'
          w='256px'
          h='183px'
        />
        <ModalHeader mb='1em'>
          <Stack>
            <Icon as={ClerkLogoIcon} w='48px' h='48px' mb={12} />
            <Heading as='h3'>Premium feature</Heading>
          </Stack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody mb='1em'>
          <Text color='gray.500'>
            Upgrade this application&apos;s plan to
            <br />
            enable this and other useful features.
          </Text>
        </ModalBody>

        <ModalFooter justifyContent='flex-start'>
          <NextLink href={billingPath(applicationId, instanceId)}>
            <Button onClick={onClose} ref={initialRef}>
              Upgrade Plan &rarr;
            </Button>
          </NextLink>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
