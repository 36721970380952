import { Link } from '@chakra-ui/react';
import React from 'react';

export type CardDocLinkProps = {
  url: string;
  subject: string;
  onClick?: () => void;
};

export function CardDocLink({
  url,
  subject,
  onClick,
}: CardDocLinkProps): JSX.Element {
  return (
    <Link
      href={url}
      target='_blank'
      textStyle='sm-medium'
      color='primary.500'
      onClick={onClick}
    >
      Learn more about {subject}
    </Link>
  );
}
