import cn from 'classnames';
import React from 'react';
import { InputProps } from './Input';
import { InputWithIcon } from './InputWithIcon';

// @ts-ignore
import styles from './SearchBar.module.scss';
import { SearchIcon } from '../../assets/icons';

export function SearchBar(props: InputProps): JSX.Element {
  return (
    <div className={styles.searchBar}>
      <InputWithIcon
        className={cn(styles.searchInput)}
        {...props}
        Icon={<SearchIcon stroke='#808080' />}
      />
    </div>
  );
};
