import React from 'react';
import copy from 'clipboard-copy';
import { Button } from '../button';
import { Tooltip } from '../tooltip';
import { ClipboardIcon } from '@clerk-ui/assets/icons';

// TODO: Importing sass variables to SCSS doesn't work in Next.js for now
// https://css-tricks.com/getting-javascript-to-talk-to-css-and-sass/
// import variables from "../styles/_variables.scss";

export type CopyToClipboardProps = {
  text: string;
  tooltipClassName?: string;
  className?: string;
  disabled?: boolean;
} & React.HTMLAttributes<HTMLButtonElement>;

export function CopyToClipboard({
  className,
  text,
  tooltipClassName,
  ...rest
}: CopyToClipboardProps): JSX.Element {
  const [tooltipText, setTooltipText] = React.useState('Click to copy');

  const onClick = async () => {
    await copy(text);
    setTooltipText('Copied!');
  };

  return (
    <Tooltip
      content={tooltipText}
      direction='top'
      className={tooltipClassName}
      handleHide={() => setTooltipText('Click to copy')}
    >
      <Button
        type='button'
        flavor='icon'
        className={className}
        onClick={onClick}
        {...rest}
      >
        <ClipboardIcon />
      </Button>
    </Tooltip>
  );
}
