import React from 'react';
import { TableProps } from './types';

import { DumbPagination } from '@components/common';

import {
  Table as TableWrapper,
  Cell,
  HeadCell,
  Head,
  Row,
  Body,
  EmptyHeadCell,
  SortableHeadCell,
} from './BaseTable';

import { Flex, Divider } from '@chakra-ui/react';

const Table = <T extends { id: string }>({
  data,
  tableSchema,
  pagination,
  emptyTableFallback,
  rowActions,
  onRowClicked,
  globalFilters,
  header,
  isLoading,
  sortingState,
  sortingColumns,
  visibleColumns = [],
}: TableProps<T>): JSX.Element => {
  //Table header should be calculated from the useTable function
  const tableHeaders = tableSchema.reduce(
    (acc, { header, key, sortingOptions }) => {
      acc.push({
        header,
        key,
        enableSorting: sortingOptions?.enableSorting,
        enableAliasSorting: sortingOptions?.enableAliasSorting,

        isVisible:
          //This is a breaking change, we should remove the conditional check here later that we will update
          // the rest of the tables with the visible columns prop
          visibleColumns.length > 0 ? visibleColumns.includes(key) : true,
      });
      return acc;
    },
    [],
  );

  //Table cells should be calculated from the useTable function
  const tableCells = tableSchema.map(
    ({ cell, key, tdProps, wrapperStyleProps, SkeletonComponent }) => ({
      cell,
      key,
      tdProps,
      //This is a breaking change, we should remove the conditional check here later that we will update
      // the rest of the tables with the visible columns prop
      isVisible:
        visibleColumns.length > 0 ? visibleColumns.includes(key) : true,
      wrapperStyleProps,
      SkeletonComponent,
    }),
  );

  const _visibleColumns =
    visibleColumns.length > 0
      ? visibleColumns
      : tableHeaders.filter(({ isHidden, isAlias }) => !isAlias && !isHidden);

  const numberOfVisibleColumns = rowActions
    ? _visibleColumns.length + 1
    : _visibleColumns.length;

  return (
    <>
      {header || globalFilters ? (
        <Flex flexDirection='column'>
          {header}
          <Divider borderColor='gray.200' borderBottomWidth='1px' m={0} />
          <Flex py={3}>{globalFilters}</Flex>
          <Divider borderColor='gray.200' borderBottomWidth='1px' m={0} />
        </Flex>
      ) : null}

      <TableWrapper>
        <Head>
          <Row isDisabled>
            {tableHeaders.map(
              ({
                header,
                key,
                enableSorting,
                enableAliasSorting,
                isVisible,
              }) => {
                if (!isVisible) {
                  return null;
                }
                {
                  return enableSorting || enableAliasSorting ? (
                    <SortableHeadCell
                      isLoading={isLoading}
                      key={header}
                      isCurrentColumnSorting={
                        sortingColumns[sortingState.column]?.alias === key ||
                        sortingState.column === key
                      }
                      sortingState={sortingState}
                    >
                      {header}
                    </SortableHeadCell>
                  ) : (
                    <HeadCell isLoading={isLoading} key={key}>
                      {header}
                    </HeadCell>
                  );
                }
              },
            )}
            {rowActions && <EmptyHeadCell width={12} />}
          </Row>
        </Head>
        <Body>
          {data.length === 0 ? (
            typeof emptyTableFallback === 'function' ? (
              emptyTableFallback(numberOfVisibleColumns, isLoading)
            ) : (
              emptyTableFallback
            )
          ) : (
            <>
              {data.map((row, index) => {
                const isLast = index === data.length - 1;

                return (
                  <Row key={row.id} onClick={() => onRowClicked(row)}>
                    {tableCells.map(
                      ({
                        cell,
                        key,
                        tdProps,
                        wrapperStyleProps,
                        SkeletonComponent,
                        isVisible,
                      }) => {
                        if (!isVisible) {
                          return null;
                        }
                        return (
                          <Cell
                            isLoading={isLoading}
                            isLast={isLast}
                            key={`${row.id}-${key}`}
                            {...tdProps}
                            wrapperStyleProps={wrapperStyleProps}
                            whiteSpace='nowrap'
                            SkeletonComponent={SkeletonComponent}
                          >
                            {cell?.(row) || row[key] || ''}
                          </Cell>
                        );
                      },
                    )}
                    {rowActions?.(row) && (
                      <Cell
                        isNumeric
                        onClick={ev => ev.stopPropagation()}
                        borderTopRightRadius='md'
                        borderBottomRightRadius='md'
                        isLast={isLast}
                        width={12}
                        isLoading={isLoading}
                      >
                        {rowActions?.(row)}
                      </Cell>
                    )}
                  </Row>
                );
              })}
            </>
          )}
        </Body>
      </TableWrapper>
      {pagination}
    </>
  );
};

Table.Pagination = DumbPagination;

export default Table;
