import React from 'react';
import { Heading } from '../heading';
import { ExpansionIcon } from '../expansionIcon';
import cn from 'classnames';
import { noop } from '@utils';

// @ts-ignore
import styles from './ExpansionPanel.module.scss';
import type { SvgrComponent } from '../../assets/icons/svg';

type ExpansionPanelProps = React.PropsWithChildren<{
  title: string;
  headingType?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  headingClassname?: 'string';
  panelContainerClassname?: string;
  /** Style customization for closed state, mostly for press surface */
  closedButtonClassname?: string;
  initialExpandedState?: boolean;
  handleChange?: (expansionState: boolean) => void;
  id: string;
  CustomExpansionIcon?: SvgrComponent;
  disabled?: boolean;
  ActionComponent?: React.ReactNode;
}>;

export function ExpansionPanel({
  title,
  headingType = 'h3',
  headingClassname,
  panelContainerClassname,
  closedButtonClassname,
  initialExpandedState = false,
  handleChange = noop,
  id,
  children,
  CustomExpansionIcon,
  disabled = false,
  /* Component to provide functionality for an action/tooltip beside the expansion panel header */
  ActionComponent,
}: ExpansionPanelProps): JSX.Element {
  const sectionId = `${id}-content`;
  const [isExpanded, setIsExpanded] = React.useState(initialExpandedState);

  const onChange = () => {
    const expandedState = !isExpanded;
    setIsExpanded(expandedState);
    handleChange(expandedState);
  };

  return (
    <div
      className={cn(panelContainerClassname, {
        [styles.noPadding]: !isExpanded,
      })}
    >
      <button
        onClick={disabled ? noop : onChange}
        id={id}
        aria-controls={sectionId}
        aria-expanded={isExpanded}
        className={cn(styles.button, {
          [styles.disabled]: disabled,
          [closedButtonClassname || styles.pressPadding]: !isExpanded,
        })}
      >
        <ExpansionIcon
          iconClassName={styles.icon}
          transitionClassName={styles.iconTransition}
          isExpanded={isExpanded}
          CustomExpansionIcon={CustomExpansionIcon}
        />
        <Heading
          as={headingType}
          className={cn(styles.heading, headingClassname)}
        >
          {title}
        </Heading>
        {ActionComponent && (
          <div className={styles.action} onClick={e => e.stopPropagation()}>
            {ActionComponent}
          </div>
        )}
      </button>
      <section
        className={cn(styles.contentContainer, {
          [styles.hidden]: !isExpanded,
        })}
        aria-labelledby={id}
        id={sectionId}
        role='region'
      >
        {children}
      </section>
    </div>
  );
}
