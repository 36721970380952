import React from 'react';
import {
  Tabs as ChakraTabs,
  TabsProps as ChakraTabsProps,
  TabList,
  TabListProps,
  TabPanels,
  TabPanelProps,
  Tab,
  TabProps,
  TabPanel,
  TabPanelsProps,
} from '@chakra-ui/react';

export type TabProp = {
  label: string;
  component: React.ReactNode;
  key: string;
};

type TabsProps = {
  tabs: TabProp[];
  appearance?: {
    tabList?: Omit<TabListProps, 'children'>;
    tab?: Omit<TabProps, 'children'>;
    tabs?: Omit<ChakraTabsProps, 'children'>;
    tabPanel?: Omit<TabPanelProps, 'children'>;
    tabPanels?: Omit<TabPanelsProps, 'children'>;
  };
  onTabChange?: (i: number) => void;
  tabIndex?: number;
};

const Tabs = ({
  tabs,
  appearance,
  onTabChange,
  tabIndex = 0,
}: TabsProps): JSX.Element => {
  return (
    <ChakraTabs {...appearance?.tabs} index={tabIndex} onChange={onTabChange}>
      <TabList {...appearance?.tabList}>
        {tabs.map(({ label, key }) => {
          return (
            <Tab key={key} {...appearance?.tab}>
              {label}
            </Tab>
          );
        })}
      </TabList>

      <TabPanels {...appearance?.tabPanels}>
        {tabs.map(({ component, key }) => {
          return (
            <TabPanel key={key} {...appearance?.tabPanel}>
              {component}
            </TabPanel>
          );
        })}
      </TabPanels>
    </ChakraTabs>
  );
};

export default Tabs;
