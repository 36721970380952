import React from 'react';
import type { BadgeProps } from '@chakra-ui/react';
import { Badge } from '@chakra-ui/react';
import Link from 'next/link';

export function NewBadge(props: BadgeProps): JSX.Element {
  return (
    <Badge variant='blue' size='sm' {...props}>
      New
    </Badge>
  );
}

export function BetaBadge(props: BadgeProps): JSX.Element {
  return (
    <Badge colorScheme='green' size='sm' {...props}>
      Beta
    </Badge>
  );
}

export function ComingSoonBadge(props: BadgeProps): JSX.Element {
  return (
    <Badge colorScheme='warning' size='sm' {...props}>
      Coming soon
    </Badge>
  );
}

export function GenericBadge(
  props: BadgeProps & { label: string; isPrimary?: boolean },
): JSX.Element {
  return (
    <Badge
      colorScheme={props.isPrimary ? 'primary' : 'gray'}
      size='sm'
      {...props}
    >
      {props.label}
    </Badge>
  );
}

export function UpdateNeededBadge(
  props: BadgeProps & { href: string },
): JSX.Element {
  return (
    <Badge variant='blue' size='sm' {...props}>
      <Link href={props.href} passHref>
        Update required
      </Link>
    </Badge>
  );
}
