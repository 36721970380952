import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';
import { useClipboard, UseClipboardOptions } from '@chakra-ui/hooks';

type CopyTextButtonProps = {
  valueToCopy: string;
  optionsOrTimeout?: UseClipboardOptions | number;
  onTrack?: () => void;
} & ButtonProps;

export function CopyTextButton({
  valueToCopy,
  optionsOrTimeout = 2000,
  onTrack,
  ...rest
}: CopyTextButtonProps): JSX.Element {
  const { hasCopied, onCopy } = useClipboard(valueToCopy, optionsOrTimeout);

  const copy = () => {
    onCopy();
    onTrack();
  };

  return (
    <Button
      size='sm'
      variant='link'
      onClick={copy}
      textTransform='uppercase'
      {...rest}
    >
      {hasCopied ? 'Copied' : 'Copy'}
    </Button>
  );
}
