import React from 'react';
import cn from 'classnames';

// @ts-ignore
import styles from './Divider.module.scss';

type DividerProps = {
  dividerClassName?: string;
};

export function Divider({ dividerClassName }: DividerProps): JSX.Element {
  return <hr className={cn(styles.divider, dividerClassName)} />;
}
