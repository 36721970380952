import React from 'react';
import { forwardRef, ButtonProps, Button } from '@chakra-ui/react';

export type AbstractButtonProps = ButtonProps;
export const AbstractButton = forwardRef<ButtonProps, 'button'>(
  (props, ref) => (
    <Button ref={ref} {...props}>
      {props.children}
    </Button>
  ),
);
