import React from 'react';
import {
  SkeletonCircle as ChakraSkeletonCircle,
  SkeletonProps as ChakraSkeletonProps,
} from '@chakra-ui/react';

export type SkeletonCircleProps = ChakraSkeletonProps;

export const SkeletonCircle = ({
  ...props
}: SkeletonCircleProps): JSX.Element => {
  return (
    <ChakraSkeletonCircle
      {...props}
      startColor='gray.50'
      endColor='gray.400'
      fadeDuration={0.8}
    />
  );
};
