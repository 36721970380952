import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import Link from 'next/link';

export type HelperLinkProps = {
  href: string;
  children?: React.ReactNode;
} & BoxProps;

export const HelperLink = ({
  children = 'Learn where to find this',
  color = 'primary.500',
  href,
  textStyle = 'sm-medium',
  ...rest
}: HelperLinkProps): JSX.Element => (
  <Link target='_blank' href={href} passHref>
    <Box as='span' textStyle={textStyle} color={color} {...rest}>
      {children}
    </Box>
  </Link>
);
