import React from 'react';
import { forwardRef, ButtonProps } from '@chakra-ui/react';

import { AbstractButton, AbstractButtonProps } from './AbstractButton';

export type SecondaryButtonProps = AbstractButtonProps;
export const SecondaryButton = forwardRef<ButtonProps, 'button'>(
  (props, ref) => (
    <AbstractButton
      ref={ref}
      _focus={{ boxShadow: 'none' }}
      _hover={{ backgroundColor: 'transparent' }}
      _active={{
        boxShadow:
          '0px 0px 0px 4px #F5F5F5, 0px 1px 2px 0px rgba(20, 20, 20, 0.05)',
      }}
      px='2.5'
      py='4'
      rounded='lg'
      fontWeight='semibold'
      lineHeight='5'
      color='gray.700'
      border='1px solid'
      borderColor='gray.200'
      fontSize='sm'
      textTransform='none'
      variant='outline'
      colorScheme='gray'
      sx={{
        '& svg': {
          height: 5,
          width: 5,
        },
      }}
      {...props}
    >
      {props.children}
    </AbstractButton>
  ),
);
