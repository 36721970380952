import React from 'react';
import NextLink from 'next/link';
import {
  Box,
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useTheme,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import { Heading } from '@components/common';
import NextImage from 'next/image';
import { BillingModalBackground } from '@clerk-ui/assets/icons';
import { useLocation } from '@hooks';
import { billingPath } from '@utils';
import { SUPPORTED_FEATURES_TITLES } from '@constants';

type BillingModalProps = {
  isOpen: boolean;
  onClose: () => void;
  features?: string[];
  downgradeAction?: () => Promise<void>;
  downgradeTo?: string;
};

export function BillingModal({
  features = [],
  isOpen,
  onClose,
  downgradeAction,
  downgradeTo,
}: BillingModalProps): JSX.Element {
  const initialRef = React.useRef();
  const { applicationId, instanceId } = useLocation();
  const theme = useTheme();

  const { colors } = theme;

  const handleDowngrade = () => {
    void downgradeAction();
    onClose();
  };

  const upgradeDescription = (
    <>
      {features.length === 1 ? (
        <>
          <Text textStyle='md-normal'>
            <strong>{SUPPORTED_FEATURES_TITLES[features[0]]}</strong> feature is
            not available in your current plan for production instances.
          </Text>
          <Text textStyle='md-normal' mt={2}>
            Upgrade this application’s plan to enable it.
          </Text>
        </>
      ) : (
        <>
          <Text textStyle='md-normal'>
            The following features are not available in your current plan.
            <UnorderedList mt={2}>
              {features.map(feature => (
                <ListItem key={feature}>
                  <strong>{SUPPORTED_FEATURES_TITLES[feature]}</strong>
                </ListItem>
              ))}
            </UnorderedList>
          </Text>
          <Text textStyle='md-normal' mt={4}>
            Pick one of the available plans to unlock the above features.
          </Text>
        </>
      )}
    </>
  );

  const downgradeDescription = (
    <>
      <Text textStyle='md-normal'>
        You are about to downgrade your plan to <strong>{downgradeTo}.</strong>
      </Text>
      <Text textStyle='md-normal' mt={2}>
        Features available to your current plan will stop working. Are you sure
        you want to continue?
      </Text>
    </>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={initialRef}
      size='lg'
    >
      <ModalOverlay />
      <ModalContent px={6} py={8} pos='relative' overflow='hidden'>
        <Icon
          as={BillingModalBackground}
          position='absolute'
          top='0'
          right='0'
          w='226px'
          h='288px'
          zIndex={-1}
        />
        <ModalHeader p={0} mb={4}>
          {!downgradeAction && (
            <Box mb={4}>
              <NextImage
                src='/assets/logos/clerk.svg'
                alt="Clerk's logo"
                height={42}
                width={42}
                layout='fixed'
              />
            </Box>
          )}
          <Heading as='h3'>
            {downgradeAction ? 'Downgrade plan' : 'Upgrade plan'}
          </Heading>
        </ModalHeader>
        <ModalBody p={0}>
          {downgradeAction ? downgradeDescription : upgradeDescription}
        </ModalBody>

        <ModalFooter justifyContent='space-between' p={0} mt={10}>
          <Button variant='ghost' onClick={onClose}>
            Cancel
          </Button>

          {!downgradeAction && (
            <NextLink href={billingPath(applicationId, instanceId)}>
              <Button onClick={onClose} ref={initialRef}>
                Upgrade plan
              </Button>
            </NextLink>
          )}

          {!!downgradeAction && (
            <Button
              onClick={handleDowngrade}
              ref={initialRef}
              colorScheme='danger'
              // TODO: investigate in theme why this important is needed to override default boxShadow
              boxShadow={`0px 0px 0px 1px ${colors.danger['500']}!important`}
            >
              Downgrade plan
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
