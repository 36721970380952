import React from 'react';
import { Box, Flex, FlexProps, Icon as IconChakra } from '@chakra-ui/react';
import { VerticalInputBox } from './VerticalInputBox';

type ImageInputBoxProps = {
  Icon: (props: React.ComponentProps<'svg'>) => JSX.Element;
  label: string;
  subLabel?: string;
  children: React.ReactNode;
} & FlexProps;

export function ImageInputBox({
  Icon,
  label,
  subLabel,
  children,
  ...rest
}: ImageInputBoxProps): JSX.Element {
  return (
    <Flex pt={4} {...rest}>
      <Box>
        <IconChakra
          as={Icon}
          color='gray.300'
          mr='1rem'
          position='relative'
          top='-3px'
          boxSize='20px'
        />
      </Box>
      <VerticalInputBox label={label} subLabel={subLabel}>
        {children}
      </VerticalInputBox>
    </Flex>
  );
}
