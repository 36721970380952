import React from 'react';
import cn from 'classnames';
import { Button } from '../button/Button';
import { XIcon } from '../../assets/icons';
import { useLocalStorage } from '@hooks/useLocalStorage';

// @ts-ignore
import styles from './Infobox.module.scss';

export type StoredInfoboxProps = {
  children: React.ReactNode;
  /** LocalStorage identifier  */
  storageKey: string;
  className?: string;
};

export function StoredInfobox({
  storageKey,
  children,
  className,
}: StoredInfoboxProps): JSX.Element {
  const [closedInfo, setClosedInfo] = useLocalStorage<string>(storageKey, '');

  const handleCloseClick = () => setClosedInfo('true');

  return (
    <>
      {closedInfo !== 'true' && (
        <div className={cn(styles.infobox, className)}>
          {children}
          <Button
            flavor='icon'
            // Use onMousedown instead of onClick to prevent closing modals or overlays
            // upon clicking on close notification button.
            // More info at /packages/shared/hooks/useDetectClickOutside.ts#L30
            onMouseDown={handleCloseClick}
          >
            <XIcon className={styles.closeButton} />
          </Button>
        </div>
      )}
    </>
  );
}

export type InfoboxProps = {
  children: React.ReactNode;
  className?: string;
};

export function Infobox({ children, className }: InfoboxProps): JSX.Element {
  return <div className={cn(styles.infobox, className)}>{children}</div>;
}
