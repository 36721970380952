import React from 'react';
import type { SupportedMimeType } from '@utils';
import { ImageUploader } from '.';
import type { FlexProps } from '@chakra-ui/react';
import {
  Box,
  Button,
  Flex,
  Icon,
  Spacer,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

export type ImageUploaderBoxProps = {
  leftIcon?: React.FunctionComponent;
  label: string;
  children: React.ReactNode;
  handleUpload: (file: File) => Promise<unknown>;
  handleDelete?: React.MouseEventHandler;
  acceptedTypes?: SupportedMimeType[];
  uploaderBoxRestProps?: FlexProps;
};

export type ImageViewerBoxProps = Pick<
  ImageUploaderBoxProps,
  'leftIcon' | 'label' | 'children'
>;

export const ImageUploaderBox = ({
  leftIcon,
  label,
  children,
  handleUpload,
  handleDelete,
  acceptedTypes,
  uploaderBoxRestProps,
}: ImageUploaderBoxProps): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <ImageUploader
        itemName={label.toLowerCase()}
        isModalOpen={isOpen}
        closeModal={onClose}
        handleSuccess={handleUpload}
        acceptedTypes={acceptedTypes}
      />

      <Flex
        p={4}
        pr={0}
        mb={2}
        justifyContent='space-between'
        {...uploaderBoxRestProps}
      >
        <Flex>
          {leftIcon && (
            <Box>
              <Icon
                as={leftIcon}
                color='gray.300'
                mr='1rem'
                position='relative'
                top='-3px'
                boxSize='20px'
              />
            </Box>
          )}
          <Flex direction='column'>
            <Text textStyle='md-medium' mb={1}>
              {label}
            </Text>

            <Flex>
              <Text
                as='button'
                type='button'
                textStyle='sm-medium'
                color='primary.500'
                style={{ whiteSpace: 'nowrap' }}
                onClick={onOpen}
              >
                Upload image
              </Text>

              {handleDelete && (
                <Text
                  as='button'
                  type='button'
                  textStyle='sm-medium'
                  color='red.500'
                  ml={3}
                  onClick={handleDelete}
                >
                  Remove
                </Text>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Button
          variant={'unstyled'}
          onClick={onOpen}
          ml={'4'}
          borderRadius='base'
          height={'48px'}
          overflow={'hidden'}
        >
          {children}
        </Button>
      </Flex>
    </>
  );
};

export const ImageViewerBox = ({
  leftIcon,
  label,
  children,
}: ImageViewerBoxProps): JSX.Element => {
  return (
    <Flex p={4} mb={2}>
      <Box>
        <Icon
          as={leftIcon}
          color='gray.300'
          mr='1rem'
          position='relative'
          top='-3px'
          boxSize='20px'
        />
      </Box>

      <Box>
        <Text textStyle='md-medium' mb={1}>
          {label}
        </Text>
      </Box>

      <Spacer />

      {children}
    </Flex>
  );
};
