import React from 'react';
import {
  Box,
  Button,
  Flex,
  FlexProps,
  Text,
  TextProps,
  useClipboard,
  useDisclosure,
} from '@chakra-ui/react';

type TextCopyProps = {
  text: string;
  toggle?: boolean;
};

export function TextCopy({ text, toggle }: TextCopyProps): JSX.Element {
  const { onToggle, isOpen } = useDisclosure();
  const { onCopy, hasCopied } = useClipboard(text);

  const containerCenterAlignStyles: FlexProps = !toggle
    ? {
        align: 'center',
      }
    : {};

  const textCenterAlignStyles: TextProps = toggle
    ? {
        pos: 'relative',
        top: '0.5',
      }
    : {};

  return (
    <Flex justify='flex-start' {...containerCenterAlignStyles}>
      <Box>
        <Text
          flex='1'
          textStyle='sm-normal'
          maxW={[null, null, '450px', '620px', '330px']}
          w='100%'
          mr='2.5'
          noOfLines={!isOpen ? 1 : 0}
          {...textCenterAlignStyles}
        >
          {text}
        </Text>
        {toggle && (
          <Button
            variant='link'
            height='unset'
            fontSize='xs'
            onClick={onToggle}
            textTransform='none'
            ml='-2px'
          >
            {isOpen ? 'Show less' : 'Show more'}
          </Button>
        )}
      </Box>
      <Button
        variant='link'
        height='unset'
        onClick={onCopy}
        alignSelf='flex-start'
      >
        {hasCopied ? 'Copied' : 'Copy'}
      </Button>
    </Flex>
  );
}
