import React from 'react';
import { Dropdown, DropdownSelection } from '../dropdown';

const FONT_WEIGHT_OPTIONS = [
  {
    label: '100 / Thin',
    value: '100',
  },
  {
    label: '200 / Extra-light',
    value: '200',
  },
  {
    label: '300 / Light',
    value: '300',
  },
  {
    label: '400 / Regular',
    value: '400',
  },
  {
    label: '500 / Medium',
    value: '500',
  },
  {
    label: '600 / Semi-bold',
    value: '600',
  },
  {
    label: '700 / Bold',
    value: '700',
  },
  {
    label: '800 / Extra-Bold',
    value: '800',
  },
  {
    label: '900 / Black',
    value: '900',
  },
];

export type FontWeightPickerProps = {
  name?: string;
  active?: boolean;
  className?: string;
  selectedFontWeight?: string;
  handleChange?: (el: DropdownSelection) => void;
};

export function FontWeightPicker({
  name,
  active,
  className,
  selectedFontWeight = FONT_WEIGHT_OPTIONS[3].value,
  handleChange,
}: FontWeightPickerProps): JSX.Element {
  return (
    <Dropdown
      active={active}
      name={name}
      selectedOption={selectedFontWeight}
      options={FONT_WEIGHT_OPTIONS}
      handleChange={handleChange}
      className={className}
    />
  );
}
