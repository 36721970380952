import React from 'react';
import {
  Box,
  HStack,
  Radio,
  Text,
  useRadioGroupContext,
  VStack,
} from '@chakra-ui/react';

type RadioButtonCardProps = {
  label: React.ReactNode;
  hint?: React.ReactNode;
  value: string;
  children?: React.ReactNode;
  isDisabled?: boolean;
  config?: React.ReactNode;
  onChange?: () => void;
};

export function RadioButtonCard(props: RadioButtonCardProps): JSX.Element {
  const { label, hint, value, children, isDisabled, config, onChange } = props;
  const radioGroupContext = useRadioGroupContext();

  return (
    <HStack
      as='label'
      w='full'
      spacing={4}
      padding={4}
      justify='flex-start'
      align='flex-start'
      cursor='pointer'
      borderRadius='lg'
      border='1px solid transparent'
      _hover={{
        borderColor: 'gray.50',
        bg: 'gray.50',
      }}
      _checked={{
        borderColor: 'gray.50',
        bg: 'gray.50',
      }}
      data-checked={radioGroupContext.value === value ? true : undefined}
      transition='all 150ms'
      position='relative'
    >
      <Box boxSize={5}>
        <Radio
          isDisabled={isDisabled}
          value={value}
          size='lg'
          onChange={onChange}
        />
      </Box>
      <VStack justify='flex-start' align='flex-start' spacing={0.5}>
        <Text textStyle='md-normal'>{label}</Text>
        {hint && (
          <Text textStyle='sm-normal' color='gray.500'>
            {hint}
          </Text>
        )}
        {children}
      </VStack>
      {config}
    </HStack>
  );
}
