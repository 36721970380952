import React from 'react';
import { Skeleton, Stack } from '@chakra-ui/react';

const InputPreviewSkeleton = (): JSX.Element => {
  return (
    <Stack>
      <Skeleton borderRadius='md' height={10} />
    </Stack>
  );
};

export default InputPreviewSkeleton;
